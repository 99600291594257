import React from 'react';
import "./Info.scss";
import img1 from "../../img/infoImg1.png";
import img2 from "../../img/infoImg2.png";
import img3 from "../../img/infoImg3.png";

function Info() {
	return (
		<div className="__info">
			<section className="__section">
				<div className="container">
					<div className="__wrapper">
						<div className="__image">
							<img src={img1} alt="img"/>
						</div>
						<div className="__text">
							Каждый наш букет — это не просто набор цветов, а уникальное творение, рожденное в момент вдохновения. Мы не повторяем букеты в точности, как на витрине, потому что каждый из них создан с индивидуальным подходом и отражает особую гармонию природы и искусства.
						</div>
					</div>
				</div>
			</section>
			<section className="__section reverse">
				<div className="container">
					<div className="__wrapper">
						<div className="__text">
							Мы верим, что цветы, подобно произведениям искусства, должны быть неповторимыми, как и эмоции, которые они вызывают. Заказывая у нас, вы получаете не просто копию, а новый, уникальный шедевр, созданный специально для вас.
						</div>
						<div className="__image">
							<img src={img2} alt="img"/>
						</div>
					</div>
				</div>
			</section>
			<section className="__section">
				<div className="container">
					<div className="__wrapper">
						<div className="__image">
							<img src={img3} alt="img"/>
						</div>
						<div className="__text">
							Такой подход подчёркивает эксклюзивность и ценность каждого букета, делает акцент на его особом, персонализированном характере и добавляет нотку художественности и уникальности.
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Info;