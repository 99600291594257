import React, { useContext, useEffect, useState } from 'react';
import "./Main.scss";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import Info from "../../components/Info/Info";
import ItemsSlider from "../../components/ItemsSlider/ItemsSlider";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { fetchFlowers, fetchTypes } from "../../http/flowerAPI";
import mainImg from "../../img/mainCarousel.png";

const Main = observer(() => {
	const { item } = useContext(Context);
	const [flowersByType, setFlowersByType] = useState({});

	useEffect(() => {
		const loadData = async () => {
			const types = await fetchTypes();
			item.setTypes(types);

			const flowers = await fetchFlowers();
			item.setFlower(flowers);

			const activeTypes = types.filter(type => type.active);
			const activeTypeIds = activeTypes.map(type => type.id);

			const groupedFlowers = activeTypes.reduce((acc, type) => {
				const flowersOfType = flowers
					.filter(flower => flower.type_id === type.id)
					.map(flower => ({
						...flower,
						typeName: type.name
					}));

				if (flowersOfType.length > 0) {
					acc[type.id] = flowersOfType;
				}

				return acc;
			}, {});

			setFlowersByType(groupedFlowers);
		};

		loadData();
	}, [item]);

	return (
		<div className="main">
			{/*<ImageCarousel images={[mainImg, mainImg2]}/>*/}
			<img className="__main_carousel" src={mainImg} alt="img"/>
			{Object.keys(flowersByType).map(typeId => (
				<ItemsSlider
					key={typeId}
					title={item.types.find(type => type.id === parseInt(typeId)).name}
					items={flowersByType[typeId]}
				/>
			))}
			<Info />
		</div>
	);
});

export default Main;
